import React, {useContext} from 'react';
import {Countries, HeaderContextKeys, RegistrationLinkByCountry} from "../../../context/header/header-context";
import {FooterContextKeys} from "../../../context/footer/footer-context";
import CountryContext from "../../../context/country/country-context";
import classnames from "classnames";
import {StaticImage} from "gatsby-plugin-image";
import {LanguageDropdownShort, LinkComponent} from "../../../components";
import HeaderCountryDropdown from "../../header/components/header-country-dropdown";
import Image from '../../../components/image/image';


interface HeaderBurgerProps {
    toggleBurger: React.MouseEventHandler,
    isOpen: boolean,
    [HeaderContextKeys.countries_dropdown]: { [key: string]: any },
    [HeaderContextKeys.about_dropdown]: { [key: string]: any },
    [HeaderContextKeys.media_dropdown]: { [key: string]: any },
    [HeaderContextKeys.get_started_link]: { [key: string]: any },
    [HeaderContextKeys.home_link]: { [key: string]: any },
    [HeaderContextKeys.pay_off_dropdown]: { [key: string]: any },
    [FooterContextKeys.privacy]: string;
    [FooterContextKeys.terms]: string;
    [FooterContextKeys.socials]: Array<any>,
}

const HeaderBurgerWelcomePage = ({
    toggleBurger, isOpen,
    [HeaderContextKeys.countries_dropdown]: countries_dropdown,
    [HeaderContextKeys.about_dropdown]: about_dropdown,
    [HeaderContextKeys.media_dropdown]: media_dropdown,
    [HeaderContextKeys.home_link]: home_link,
    [HeaderContextKeys.pay_off_dropdown]: pay_off_dropdown,
    [FooterContextKeys.privacy]: privacy,
    [FooterContextKeys.terms]: terms,
    [FooterContextKeys.socials]: socials
}: HeaderBurgerProps) => {

    const {countryCode} = useContext(CountryContext);

    return (
        <div
            className={classnames('landing-burger', {open: isOpen})}
        >
            <div className="landing-burger__header">
                <div className="d-flex align-items-center">
                    <LinkComponent className="me-4" to="/">
                        <StaticImage
                            src="../../../images/collectius-logo-white.svg"
                            alt="Collectius logo"
                            width={138}
                            height={27}
                        />
                    </LinkComponent>

                    <HeaderCountryDropdown
                        {...{
                            [HeaderContextKeys.countries_dropdown]: countries_dropdown,
                        }}
                    />
                </div>
                <button
                    className="landing-burger__btn-close"
                    onClick={toggleBurger}
                    aria-label="Close menu"
                >
                    <div className="icon icon-cross-white"/>
                </button>
            </div>
            <div className="landing-burger__subHeader">
                <LanguageDropdownShort/>
            </div>
            <div className="landing-burger__links">
                <LinkComponent
                    className="main-header__link"
                    to="/"
                    aria-label={home_link.label}
                >
                    {home_link.label}
                </LinkComponent>

                {!!about_dropdown.items && !!Object.keys(about_dropdown.items)?.length && (
                    <React.Fragment>
                        <LinkComponent
                            className="landing-burger__link d-block"
                            to="/about"
                            aria-label={about_dropdown.items['about-us']}
                        >
                            {about_dropdown.items['about-us']}
                        </LinkComponent>
                        <LinkComponent
                            className="landing-burger__link d-block"
                            to="/careers"
                            aria-label={about_dropdown.items.careers}
                        >
                            {about_dropdown.items.careers}
                        </LinkComponent>
                        <LinkComponent
                            className="landing-burger__link d-block"
                            to="/partner-form"
                            aria-label={about_dropdown.items.partner}
                        >
                            {about_dropdown.items.partner}
                        </LinkComponent>
                    </React.Fragment>
                )}
                {!!pay_off_dropdown.items && (
                    <React.Fragment>
                        <a
                            className="landing-burger__link d-block"
                            href={RegistrationLinkByCountry[countryCode]}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label={pay_off_dropdown.items['sign-up']}
                        >
                            {pay_off_dropdown.items['sign-up']}
                        </a>
                        <LinkComponent
                            className="landing-burger__link d-block mb-4"
                            to="/contact-us"
                            aria-label={pay_off_dropdown.items.contact}
                        >
                            {pay_off_dropdown.items.contact}
                        </LinkComponent>
                    </React.Fragment>
                )}

                {!!media_dropdown.items && !!Object.keys(media_dropdown.items)?.length && (
                    <React.Fragment>
                        <LinkComponent
                            className="landing-burger__link d-block"
                            to="/news"
                            aria-label={media_dropdown.items.news}
                        >
                            {media_dropdown.items.news}
                        </LinkComponent>
                        <LinkComponent
                            className="landing-burger__link d-block"
                            to="/articles"
                            aria-label={media_dropdown.items.articles}
                        >
                            {media_dropdown.items.articles}
                        </LinkComponent>
                        <LinkComponent
                            className="landing-burger__link d-block"
                            to="/partner-form"
                            aria-label={about_dropdown.items.partner}
                        >
                            {about_dropdown.items.partner}
                        </LinkComponent>
                    </React.Fragment>
                )}

                <LinkComponent
                    className="landing-burger__link d-block"
                    to="/terms-and-conditions"
                    aria-label={terms}
                >
                    {terms}
                </LinkComponent>
                <LinkComponent
                    className={classnames('landing-burger__link d-block', {'mb-3': countryCode !== Countries.in})}
                    to="/privacy-policy"
                    aria-label={privacy}
                >
                    {privacy}
                </LinkComponent>
                {countryCode === Countries.in && (
                    <>
                        <LinkComponent
                            className="landing-burger__link d-block"
                            to="/gro-summary"
                            aria-label="GRO Policy"
                        >
                            GRO Policy
                        </LinkComponent>
                        <LinkComponent
                            className="landing-burger__link d-block mb-3"
                            to="/grievance-redressal-policy"
                            aria-label="Grievance Redressal Policy"
                        >
                            Grievance Redressal Policy
                        </LinkComponent>
                    </>
                )}
            </div>
            <div className="landing-burger__socials pt-5">
                {socials.map(({icon, valueByCountry}, index) => (
                    <a
                        key={index}
                        className="landing-burger__social-link me-4"
                        href={valueByCountry[countryCode]}
                        rel="noopener noreferrer"
                        target="_blank"
                        aria-label={icon}
                    >
                        <Image
                            fileName={icon}
                            rawPath={`/icons/${icon}`}
                            alt={`${icon}`}
                            width={24}
                            height={24}
                        />
                    </a>
                ))}
            </div>
        </div>
    );
};

export default HeaderBurgerWelcomePage;
