import * as React from 'react';
import { Helmet } from 'react-helmet';
import {Countries, LanguagesKeys} from "../../../context/header/header-context";


const scriptDataByCountry = Object.freeze({
    [Countries.id]: 'eyJpZCI6IklEX1dlYmNoYXRfTGl2ZSIsImljU2VydmVycyI6WyJjaGF0LmNvbGxlY3RpdXMuY29tIl0sInJldmVyc2VQcm94eVVybCI6bnVsbCwidXNlQ2FhUyI6ZmFsc2UsImNhYXNVcmwiOm51bGwsInVzZUh0dHBzIjp0cnVlfQ==',
    [Countries.th]: 'eyJpZCI6IlRIX1dlYmNoYXRfTGl2ZSIsImljU2VydmVycyI6WyJjaGF0LmNvbGxlY3RpdXMuY29tIl0sInJldmVyc2VQcm94eVVybCI6bnVsbCwidXNlQ2FhUyI6ZmFsc2UsImNhYXNVcmwiOm51bGwsInVzZUh0dHBzIjp0cnVlfQ==',
    [Countries.ph]: 'eyJpZCI6IlBIX1dlYmNoYXRfTGl2ZSIsImljU2VydmVycyI6WyJjaGF0LmNvbGxlY3RpdXMuY29tIl0sInJldmVyc2VQcm94eVVybCI6bnVsbCwidXNlQ2FhUyI6ZmFsc2UsImNhYXNVcmwiOm51bGwsInVzZUh0dHBzIjp0cnVlfQ==',
    [Countries.my]: 'eyJpZCI6Ik1ZX1dlYmNoYXRfTGl2ZSIsImljU2VydmVycyI6WyJjaGF0LmNvbGxlY3RpdXMuY29tIl0sInJldmVyc2VQcm94eVVybCI6bnVsbCwidXNlQ2FhUyI6ZmFsc2UsImNhYXNVcmwiOm51bGwsInVzZUh0dHBzIjp0cnVlfQ==',
    [Countries.vn]: 'eyJpZCI6IlZOX1dlYmNoYXRfTGl2ZSIsImljU2VydmVycyI6WyJjaGF0LmNvbGxlY3RpdXMuY29tIl0sInJldmVyc2VQcm94eVVybCI6bnVsbCwidXNlQ2FhUyI6ZmFsc2UsImNhYXNVcmwiOm51bGwsInVzZUh0dHBzIjp0cnVlfQ==',
    [Countries.in]: 'eyJpZCI6IklOX1dlYmNoYXRfTGl2ZSIsImljU2VydmVycyI6WyJjaGF0LmNvbGxlY3RpdXMuaW4iXSwicmV2ZXJzZVByb3h5VXJsIjpudWxsLCJ1c2VDYWFTIjpmYWxzZSwiY2Fhc1VybCI6bnVsbCwidXNlSHR0cHMiOnRydWV9'
});

const googleSiteVerification = Object.freeze({
    [Countries.in]: 'ASIePw6uXU6yprFtULi8G-TuafE32P6jSe8qECw-8tU',
    [Countries.th]: '528CY21R52kVc2GXwduJvkI97jwZhZj8Zz9HGB00k7k',
    [Countries.id]: '528CY21R52kVc2GXwduJvkI97jwZhZj8Zz9HGB00k7k',
    [Countries.ph]: '528CY21R52kVc2GXwduJvkI97jwZhZj8Zz9HGB00k7k',
    [Countries.my]: '528CY21R52kVc2GXwduJvkI97jwZhZj8Zz9HGB00k7k',
    [Countries.sg]: '528CY21R52kVc2GXwduJvkI97jwZhZj8Zz9HGB00k7k',
    [Countries.vn]: '528CY21R52kVc2GXwduJvkI97jwZhZj8Zz9HGB00k7k',
});

interface CommonHelmetProps {
    currentCountry: string;
    langKey: string;
    title?: string;
    description?: string;
}

const CommonHelmet = ({
    currentCountry, langKey, description,
    title,
}: CommonHelmetProps) => (
    <Helmet>
        <html lang={langKey} />
        <title>{title ?? 'Collectius'}</title>
        {description && (
            <meta name="description" content={description} />
        )}

        <style>
            {`
                body {
                    -moz-font-feature-settings: "liga" on;
                    -moz-osx-font-smoothing: grayscale;
                    -webkit-font-smoothing: antialiased;
                        font-feature-settings: "liga" on;
                        text-rendering: optimizeLegibility;
                        overflow: auto;
                        overflow-x: hidden;
                        -ms-overflow-style: none;  // IE 10+
                    }
                    .cx-widget .cx-side-button-group {
                        z-index: 2000!important;
                    }
            `}
        </style>

        <style>
            {`
                * {
                    font-family: ${langKey !== LanguagesKeys.vn ? '\'TT Commons\', Tahoma, Geneva, Verdana, sans-serif' : 'Tahoma, Arial'};
                }
            `}
        </style>

        {[Countries.id, Countries.my, Countries.ph, Countries.th, Countries.vn, Countries.in].some(country => country === currentCountry) && (
            <script
                src="https://apps.mypurecloud.com/pureconnect-widgets"
                // eslint-disable-next-line react/no-unknown-property
                pureconnect-widgets-data={scriptDataByCountry[currentCountry]}
                defer
            />
        )}

        {[Countries.id, Countries.my, Countries.ph, Countries.th, Countries.in, Countries.vn, Countries.sg].some(country => country === currentCountry) && (
            <meta name="google-site-verification" content={googleSiteVerification[currentCountry]} />
        )}

        {/* Customer survey */}
        <script
            src="https://mfpembedcdnweu.azureedge.net/mfpembedcontweu/Embed.js"
            type="text/javascript"
            defer 
        ></script>
        <link
            rel="stylesheet"
            fetchPriority="low"
            href="https://mfpembedcdnweu.azureedge.net/mfpembedcontweu/Embed.css"
        />

    </Helmet>
);

export default CommonHelmet;
