exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-id-tsx": () => import("./../../../src/pages/about.id.tsx" /* webpackChunkName: "component---src-pages-about-id-tsx" */),
  "component---src-pages-about-my-tsx": () => import("./../../../src/pages/about.my.tsx" /* webpackChunkName: "component---src-pages-about-my-tsx" */),
  "component---src-pages-about-ph-tsx": () => import("./../../../src/pages/about.ph.tsx" /* webpackChunkName: "component---src-pages-about-ph-tsx" */),
  "component---src-pages-about-th-tsx": () => import("./../../../src/pages/about.th.tsx" /* webpackChunkName: "component---src-pages-about-th-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-about-vn-tsx": () => import("./../../../src/pages/about.vn.tsx" /* webpackChunkName: "component---src-pages-about-vn-tsx" */),
  "component---src-pages-articles-id-tsx": () => import("./../../../src/pages/articles.id.tsx" /* webpackChunkName: "component---src-pages-articles-id-tsx" */),
  "component---src-pages-articles-my-tsx": () => import("./../../../src/pages/articles.my.tsx" /* webpackChunkName: "component---src-pages-articles-my-tsx" */),
  "component---src-pages-articles-ph-tsx": () => import("./../../../src/pages/articles.ph.tsx" /* webpackChunkName: "component---src-pages-articles-ph-tsx" */),
  "component---src-pages-articles-th-tsx": () => import("./../../../src/pages/articles.th.tsx" /* webpackChunkName: "component---src-pages-articles-th-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-articles-vn-tsx": () => import("./../../../src/pages/articles.vn.tsx" /* webpackChunkName: "component---src-pages-articles-vn-tsx" */),
  "component---src-pages-careers-id-tsx": () => import("./../../../src/pages/careers.id.tsx" /* webpackChunkName: "component---src-pages-careers-id-tsx" */),
  "component---src-pages-careers-my-tsx": () => import("./../../../src/pages/careers.my.tsx" /* webpackChunkName: "component---src-pages-careers-my-tsx" */),
  "component---src-pages-careers-ph-tsx": () => import("./../../../src/pages/careers.ph.tsx" /* webpackChunkName: "component---src-pages-careers-ph-tsx" */),
  "component---src-pages-careers-th-tsx": () => import("./../../../src/pages/careers.th.tsx" /* webpackChunkName: "component---src-pages-careers-th-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-careers-vn-tsx": () => import("./../../../src/pages/careers.vn.tsx" /* webpackChunkName: "component---src-pages-careers-vn-tsx" */),
  "component---src-pages-contact-us-id-tsx": () => import("./../../../src/pages/contact-us.id.tsx" /* webpackChunkName: "component---src-pages-contact-us-id-tsx" */),
  "component---src-pages-contact-us-my-tsx": () => import("./../../../src/pages/contact-us.my.tsx" /* webpackChunkName: "component---src-pages-contact-us-my-tsx" */),
  "component---src-pages-contact-us-ph-tsx": () => import("./../../../src/pages/contact-us.ph.tsx" /* webpackChunkName: "component---src-pages-contact-us-ph-tsx" */),
  "component---src-pages-contact-us-th-tsx": () => import("./../../../src/pages/contact-us.th.tsx" /* webpackChunkName: "component---src-pages-contact-us-th-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-contact-us-vn-tsx": () => import("./../../../src/pages/contact-us.vn.tsx" /* webpackChunkName: "component---src-pages-contact-us-vn-tsx" */),
  "component---src-pages-grievance-redressal-policy-tsx": () => import("./../../../src/pages/grievance-redressal-policy.tsx" /* webpackChunkName: "component---src-pages-grievance-redressal-policy-tsx" */),
  "component---src-pages-gro-summary-tsx": () => import("./../../../src/pages/gro-summary.tsx" /* webpackChunkName: "component---src-pages-gro-summary-tsx" */),
  "component---src-pages-index-id-tsx": () => import("./../../../src/pages/index.id.tsx" /* webpackChunkName: "component---src-pages-index-id-tsx" */),
  "component---src-pages-index-my-tsx": () => import("./../../../src/pages/index.my.tsx" /* webpackChunkName: "component---src-pages-index-my-tsx" */),
  "component---src-pages-index-ph-tsx": () => import("./../../../src/pages/index.ph.tsx" /* webpackChunkName: "component---src-pages-index-ph-tsx" */),
  "component---src-pages-index-th-tsx": () => import("./../../../src/pages/index.th.tsx" /* webpackChunkName: "component---src-pages-index-th-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-index-vn-tsx": () => import("./../../../src/pages/index.vn.tsx" /* webpackChunkName: "component---src-pages-index-vn-tsx" */),
  "component---src-pages-news-id-tsx": () => import("./../../../src/pages/news.id.tsx" /* webpackChunkName: "component---src-pages-news-id-tsx" */),
  "component---src-pages-news-my-tsx": () => import("./../../../src/pages/news.my.tsx" /* webpackChunkName: "component---src-pages-news-my-tsx" */),
  "component---src-pages-news-ph-tsx": () => import("./../../../src/pages/news.ph.tsx" /* webpackChunkName: "component---src-pages-news-ph-tsx" */),
  "component---src-pages-news-th-tsx": () => import("./../../../src/pages/news.th.tsx" /* webpackChunkName: "component---src-pages-news-th-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-news-vn-tsx": () => import("./../../../src/pages/news.vn.tsx" /* webpackChunkName: "component---src-pages-news-vn-tsx" */),
  "component---src-pages-partner-form-id-tsx": () => import("./../../../src/pages/partner-form.id.tsx" /* webpackChunkName: "component---src-pages-partner-form-id-tsx" */),
  "component---src-pages-partner-form-my-tsx": () => import("./../../../src/pages/partner-form.my.tsx" /* webpackChunkName: "component---src-pages-partner-form-my-tsx" */),
  "component---src-pages-partner-form-ph-tsx": () => import("./../../../src/pages/partner-form.ph.tsx" /* webpackChunkName: "component---src-pages-partner-form-ph-tsx" */),
  "component---src-pages-partner-form-th-tsx": () => import("./../../../src/pages/partner-form.th.tsx" /* webpackChunkName: "component---src-pages-partner-form-th-tsx" */),
  "component---src-pages-partner-form-tsx": () => import("./../../../src/pages/partner-form.tsx" /* webpackChunkName: "component---src-pages-partner-form-tsx" */),
  "component---src-pages-partner-form-vn-tsx": () => import("./../../../src/pages/partner-form.vn.tsx" /* webpackChunkName: "component---src-pages-partner-form-vn-tsx" */),
  "component---src-pages-privacy-policy-detail-id-tsx": () => import("./../../../src/pages/privacy-policy-detail.id.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-detail-id-tsx" */),
  "component---src-pages-privacy-policy-detail-my-tsx": () => import("./../../../src/pages/privacy-policy-detail.my.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-detail-my-tsx" */),
  "component---src-pages-privacy-policy-detail-ph-tsx": () => import("./../../../src/pages/privacy-policy-detail.ph.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-detail-ph-tsx" */),
  "component---src-pages-privacy-policy-detail-th-tsx": () => import("./../../../src/pages/privacy-policy-detail.th.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-detail-th-tsx" */),
  "component---src-pages-privacy-policy-detail-tsx": () => import("./../../../src/pages/privacy-policy-detail.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-detail-tsx" */),
  "component---src-pages-privacy-policy-detail-vn-tsx": () => import("./../../../src/pages/privacy-policy-detail.vn.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-detail-vn-tsx" */),
  "component---src-pages-privacy-policy-id-tsx": () => import("./../../../src/pages/privacy-policy.id.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-id-tsx" */),
  "component---src-pages-privacy-policy-my-tsx": () => import("./../../../src/pages/privacy-policy.my.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-my-tsx" */),
  "component---src-pages-privacy-policy-ph-tsx": () => import("./../../../src/pages/privacy-policy.ph.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-ph-tsx" */),
  "component---src-pages-privacy-policy-th-tsx": () => import("./../../../src/pages/privacy-policy.th.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-th-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-privacy-policy-vn-tsx": () => import("./../../../src/pages/privacy-policy.vn.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-vn-tsx" */),
  "component---src-pages-terms-and-conditions-id-tsx": () => import("./../../../src/pages/terms-and-conditions.id.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-id-tsx" */),
  "component---src-pages-terms-and-conditions-my-tsx": () => import("./../../../src/pages/terms-and-conditions.my.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-my-tsx" */),
  "component---src-pages-terms-and-conditions-ph-tsx": () => import("./../../../src/pages/terms-and-conditions.ph.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-ph-tsx" */),
  "component---src-pages-terms-and-conditions-th-tsx": () => import("./../../../src/pages/terms-and-conditions.th.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-th-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-terms-and-conditions-vn-tsx": () => import("./../../../src/pages/terms-and-conditions.vn.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-vn-tsx" */),
  "component---src-pages-welcome-page-my-tsx": () => import("./../../../src/pages/welcome-page.my.tsx" /* webpackChunkName: "component---src-pages-welcome-page-my-tsx" */),
  "component---src-pages-welcome-page-tsx": () => import("./../../../src/pages/welcome-page.tsx" /* webpackChunkName: "component---src-pages-welcome-page-tsx" */),
  "component---src-templates-article-details-article-details-tsx": () => import("./../../../src/templates/article-details/article-details.tsx" /* webpackChunkName: "component---src-templates-article-details-article-details-tsx" */),
  "component---src-templates-job-details-job-details-tsx": () => import("./../../../src/templates/job-details/job-details.tsx" /* webpackChunkName: "component---src-templates-job-details-job-details-tsx" */),
  "component---src-templates-news-details-news-details-tsx": () => import("./../../../src/templates/news-details/news-details.tsx" /* webpackChunkName: "component---src-templates-news-details-news-details-tsx" */)
}

