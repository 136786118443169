import React from 'react';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import PageContainer from "./src/common/page-container";

import "./src/styles/styles.scss";
import WelcomePageContainer from "./src/common/welcome-page/welcome-page-container";

export const shouldUpdateScroll = () => {
    global.window.history.scrollRestoration = 'manual';
    setTimeout(() => {
        global.window.scrollTo(0, 0);
    }, 100);
    return false;
};

const languageByKey = Object.freeze({
    en: 'english',
    id: 'indonesian',
    th: 'thai',
    my: 'malay',
    ph: 'filipino',
    vn: 'vietnamese',
});

export const wrapPageElement = ({element, props}) => {
    const {
        pageContext: {langKey, commonComponentsData},
        path,
    } = props;
    const pageName = path.replace(langKey, '').split('/').filter(Boolean)[0];
    // index page always has empty pageName
    const isIndexPage = !pageName?.length;

    const data = props.data || {};
    const languageKey = languageByKey[langKey];
    const languageData = data[languageKey];
    const pageData = isIndexPage
        ? languageData.index
        // handling both kebab-case and snake_case for pages created by createPages and files
        : languageData?.[pageName] || languageData?.[pageName?.replaceAll('-', '_')] || {};

    return !path.includes("welcome-page") ? (
        <PageContainer
            langKey={langKey}
            commonComponentsData={commonComponentsData}
            meta={pageData.metadataByCountry}
            isInIndexPage={isIndexPage}
        >
            {element}
        </PageContainer>
    ) : (<WelcomePageContainer langKey={langKey}
        commonComponentsData={commonComponentsData}
        meta={pageData.metadataByCountry}>
        {element}
    </WelcomePageContainer>);
};
