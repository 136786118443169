import React, {
    Fragment, ReactNode, useMemo,
} from "react";

import {
    HeaderProps,
} from "../../../context/header/header-context";
import {FooterContextKeys, FooterProps,} from "../../../context/footer/footer-context";
import CountryContext from "../../../context/country/country-context";
import LanguageContext from "../../../context/language/language-context";
import {CustomerSurveyContextProps} from "../../../context/customer-survey/customer-survey-context";

import Footer from '../../footer';

import CommonHelmet from "./common-helmet";
import HeaderWelcomePage from "../welcome-page-header";
import useCurrentCountry from "../../page-container/hooks/use-current-country";
import CookieConsent from "../../page-container/cookie-overlay/cookie-consent";

interface PageContainerProps {
    children: ReactNode;
    langKey: string;
    commonComponentsData: {
        header: HeaderProps;
        footer: FooterProps;
        customerSurvey: CustomerSurveyContextProps;
    };
    meta?: {
        [key: string]: {
            title: string;
            description: string;
        };
    }
}

const WelcomePageContainer = ({
    children, langKey,
    commonComponentsData, meta,
}: PageContainerProps) => {
    const {currentCountry} = useCurrentCountry();

    const currentMeta = useMemo(
        () => meta?.[currentCountry] ?? {},
        [meta, currentCountry]
    );

    return (
        <Fragment>
            <CookieConsent></CookieConsent>
            <CommonHelmet
                currentCountry={currentCountry}
                langKey={langKey}
                {...currentMeta}
            />
            <CountryContext.Provider
                value={{
                    countryCode: currentCountry,
                }}
            >
                <LanguageContext.Provider value={langKey}>
                    <HeaderWelcomePage
                        {...commonComponentsData.header}
                        {...{
                            [FooterContextKeys.privacy]:
                                commonComponentsData.footer[FooterContextKeys.privacy],
                            [FooterContextKeys.terms]:
                                commonComponentsData.footer[FooterContextKeys.terms],
                            [FooterContextKeys.socials]:
                                commonComponentsData.footer[FooterContextKeys.socials],
                        }}
                    />
                    {children}
                    <Footer {...commonComponentsData.footer} />
                </LanguageContext.Provider>
            </CountryContext.Provider>
        </Fragment>
    );
};

export default WelcomePageContainer;
